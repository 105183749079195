import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
previousTarget = null;
    connect() {

    }

    highlight(event) {
        if (this.previousTarget != null) { this.previousTarget.classList.remove("bg-cyan-100"); }
        this.previousTarget = event.currentTarget;
        event.currentTarget.classList.add("bg-cyan-100");
    }
}
